



















































import {
    Component,
    Vue,
} from 'vue-property-decorator';
import { Core } from '../../store/core'
import { Auth } from '../../store/auth'
import { User } from '../../store/user'
import { App } from "@/store/app";
@Component({
    components: {

    },
    computed: {}
})

export default class Forgot extends Vue {

    form: any = {}
    dialog:boolean = false
    service:any = {}
    async created() {
        await this.getTermService()
    }

    async prePareRegister(){
        this.dialog = true;
    }

    async register() {
        let storeUser = await Core.postHttp(`api/register/`, this.form)
        if (storeUser.id) {
            await App.success("สมัครสมาชิกสำเร็จ")
            await this.$router.push('/login')
        } else {
            await App.error('ไม่สามารถสมัครสมาชิกได้');
        }
    }

    async getTermService(){
        let service = await Core.getHttp(`/api/default/termservice/`)
        this.service = service[service.length-1]
    }


    async closeDialog(){

    }

}
